import { computed } from "vue";
import { useRoute } from "vue-router";
import { formatDateDMY } from "@/utils/formatter/dateFormatter";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";
import { storeToRefs } from "pinia";

import CostFilter from "@/components/filters/apps/CostFilter.vue";
import AppStatusFilter from "@/components/filters/apps/StatusFilter.vue";
import DatePublicFilter from "@/components/filters/apps/DatePublicFilter.vue";
import DateApplicationFilter from "@/components/filters/apps/DateApplicationFilter.vue";
import DateAuctionFilter from "@/components/filters/apps/DateAuctionFilter.vue";

import CostContractFilter from "@/components/filters/contracts/Cost.vue";
import GuaranteeFilter from "@/components/filters/contracts/Guarantee.vue";
import DateContractFilter from "@/components/filters/contracts/Date.vue";
import ContractStatusFilter from "@/components/filters/contracts/Status.vue";
import LotInfoFilter from "@/components/filters/common/LotInfoFilter.vue";
import LotTagsFilter from "@/components/filters/common/LotTagsFilter.vue";

import type { Ref } from "vue";
import type { PreFiltrationFormI } from "@/utils/getters/defaultFilters";
import type { CurrencyI, StatusI } from "@/stores/manuals/ManualsInterface";
import type { ManagerI } from "~/stores/auth/UserInterface";
import type { TenderTagI } from "~/stores/search/LotItemInterface";
import type { CostFilterI, DateFilterI } from "~/stores/search/SearchFormInterface";

import { Tabs } from "@/router/tabs";
import useManualsStore from "@/stores/manuals/useManualsStore";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import useSearchStore from "~/stores/search/useSearchStore";

function getCurrencySign(alpha: string) {
  switch(alpha) {
    case 'RUB': return '₽';
    case 'EUR': return '€';
    case 'USD': return '$';
    case 'KZT': return '₸';
    default: return '';
  }
}

export function getDateString(date: DateFilterI, title?: string) {
  if (date.dateFrom || date.dateTo)
    return [
      title ? title : undefined,
      date.dateFrom && `от ${ formatDateDMY(date.dateFrom) }`,
      date.dateTo && `до ${ formatDateDMY(date.dateTo) }`,
    ].filter(Boolean).join(' ')
  else return null;
}

function getCostString(cost: CostFilterI, currencies: CurrencyI[], costTitle = 'Цена:', uncertainCostTitle = '') {
  if (cost.costFrom || cost.costTo || !cost.uncertainCost)
    return [
      (cost.costFrom || cost.costTo) && costTitle,
      cost.costFrom && `от ${ cost.costFrom } ${ getCurrencySign(currencies.find(s => s.id === cost.currency)?.alpha) }`,
      cost.costTo && `до ${ cost.costTo } ${ getCurrencySign(currencies.find(s => s.id === cost.currency)?.alpha) }`,
      !cost.uncertainCost && `Исключая неопределенную цену${ uncertainCostTitle ? ' ' + uncertainCostTitle : ''}`,
    ].filter(Boolean).join(' ')
  else return null;
}

function getStatusString(statusIds: number[], statuses: StatusI[], withoutStatus = false) {
  return [withoutStatus && 'Без статуса', ...statusIds.map(e => statuses.find(s => s.id === e)?.title)].filter(Boolean).join(', ')
}

function getLotInfoString(govRuIds: string[], customerInns: string[], keywords: string[], responsible: ManagerI[], withoutResponsible: boolean) {
  return [
    govRuIds[0] && `Номер закупки: ${ govRuIds[0] }`,
    customerInns[0] && `ИНН: ${ customerInns[0] }`,
    keywords.length && `Ключевые слова: ${ keywords.join(', ') }`,
    responsible[0] && `Ответственный: ${ responsible[0]?.fio }`,
    withoutResponsible && `Без ответственного`,
  ].filter(Boolean).join(', ')
}

function getTagsValue(tagIds: number[], tags: TenderTagI[]) {
  if (!tagIds.length) return '';
  const list = tagIds.map(e => tags.find(t => t.id === e)).filter(Boolean)
  const innerString0 = list.slice(0, 4).map(e => `<span style="width: 12px; height: 12px; background: ${ e.color }; border-radius: 2px; margin-top: 4px; flex-shrink: 0;"></span>`).join('')
  const innerString = list.length > 4 ? innerString0 + `<span style="line-height: 12px; font-size: 10px; align-self: end;"> + ${ list.length - 4 } </span>` : innerString0;
  return `<span style="display: flex; flex-flow: row; gap: 4px;">${ innerString }</span>`;
}

export function useFiltersPanel<T extends PreFiltrationFormI>(preFiltrationForm: Ref<T>, defaultFiltrationForm: T, applyFilters: Function) {

  const manualsStore = useManualsStore();

  const route = useRoute();
  const tabName = computed(() => route.name);

  const { enableLotManagement } = useUserAccess();

  const searchStore = useSearchStore();
  const { tags, searchPageMode } = storeToRefs(searchStore);

  function getNotEmptyPreFiltration() {
    const tempPreFiltration = cloneDeep(preFiltrationForm.value);
    for (const [key, value] of Object.entries(preFiltrationForm.value)) {
      // @ts-ignore
      if (isEqual(value, defaultFiltrationForm[key])) {
        // @ts-ignore
        delete tempPreFiltration[key];
      }
    }
    return tempPreFiltration;
  }

  const notEmptyPreFiltration = computed(() => getNotEmptyPreFiltration());
  const hasActiveFilters = computed(() => !!Object.keys(notEmptyPreFiltration.value).length);

  function onResetClick() {
    if (hasActiveFilters.value) {
      preFiltrationForm.value = { ...defaultFiltrationForm };
      applyFilters()
    }
  }

  function getAppCostValue() {
    return [
      getCostString(preFiltrationForm.value.cost, manualsStore.currencies, 'НМЦК:', 'НМЦК'),
      getCostString(preFiltrationForm.value.costApp, manualsStore.currencies, 'ОЗ:', 'ОЗ')
    ].filter(Boolean).join(', ')
  }

  function getAppStatusValue() {
    return getStatusString(preFiltrationForm.value.appStatusIds, manualsStore.appStatuses, preFiltrationForm.value.withoutStatus)
  }

  function getContractCostValue() {
    return getCostString(preFiltrationForm.value.costContract, manualsStore.currencies)
  }

  function getContractGuaranteeValue() {
    return [
      getCostString(preFiltrationForm.value.costContractGuarantee, manualsStore.currencies, 'ОИК:', 'ОИК'),
      getCostString(preFiltrationForm.value.costPostContractGuarantee, manualsStore.currencies, 'Цена:', '(гарантийные обязательства)'),
    ].filter(Boolean).join(', ')
  }

  function getContractDatesValue() {
    return [
      getDateString(preFiltrationForm.value.datePlan, 'План:'),
      getDateString(preFiltrationForm.value.dateFact, 'Факт:'),
      getDateString(preFiltrationForm.value.dateContractSign, 'Заключен:'),
    ].filter(Boolean).join(', ')
  }

  function getContractStatusValue() {
    return getStatusString(preFiltrationForm.value.contractStatusIds, manualsStore.contractStatuses)
  }

  function getLotInfoValue() {
    return getLotInfoString(preFiltrationForm.value.govRuIds, preFiltrationForm.value.customerInns || [], preFiltrationForm.value.lotsSearch.keywords || [], preFiltrationForm.value.responsiblePersonIds || [], preFiltrationForm.value.withoutResponsible);
  }

  const searchColumns = computed(() => ({
    cost: { headerLabel: 'Цена', isSort: true, filterComponent: CostFilter, value: getAppCostValue(), placement: 'bottom-start' },
    datePublic: { headerLabel: 'Дата публикации', isSort: true, filterComponent: DatePublicFilter, value: getDateString(preFiltrationForm.value.datePublic), placement: 'bottom-start' },
    dateApplication: { headerLabel: 'Дата окончания подачи заявок', isSort: true, filterComponent: DateApplicationFilter, value: getDateString(preFiltrationForm.value.dateApplication), placement: 'bottom-start' },
    status: { headerLabel: 'Статус заявки', isSort: true, invisible: !enableLotManagement.value || searchPageMode.value === 'simpleSearchMode', filterComponent: AppStatusFilter, value: getAppStatusValue() },
    tagIds: { headerLabel: 'Теги', invisible: !tags.value.length || searchPageMode.value === 'simpleSearchMode', filterComponent: LotTagsFilter, value: getTagsValue(preFiltrationForm.value.tagIds, tags.value), valueAsHTML: true },
    lot: { headerLabel: 'Тендер', invisible: searchPageMode.value !== 'templateMode', filterComponent: LotInfoFilter, value: getLotInfoValue() },
  }))

  const appsColumns = computed(() => ({
    cost: { headerLabel: 'Цена', isSort: true, filterComponent: CostFilter, value: getAppCostValue(), placement: 'bottom-start' },
    datePublic: { headerLabel: 'Дата публикации', invisible: ![Tabs.Applications.Calculation].includes(tabName.value), isSort: true, filterComponent: DatePublicFilter, value: getDateString(preFiltrationForm.value.datePublic), placement: 'bottom-start' },
    dateApplication: { headerLabel: 'Дата окончания подачи заявок', invisible: ![Tabs.Applications.Main, Tabs.Applications.Calculation, Tabs.Applications.Participation].includes(tabName.value), isSort: true, filterComponent: DateApplicationFilter, value: getDateString(preFiltrationForm.value.dateApplication), placement: 'bottom-start' },
    dateAuction: { headerLabel: 'Дата торгов', invisible: ![Tabs.Applications.Main, Tabs.Applications.Participation, Tabs.Applications.Bargaining].includes(tabName.value), isSort: true, filterComponent: DateAuctionFilter, value: getDateString(preFiltrationForm.value.dateAuction), placement: 'bottom-start' },
    status: { headerLabel: 'Статус заявки', invisible: tabName.value !== Tabs.Applications.Main, isSort: true, filterComponent: AppStatusFilter, value: getAppStatusValue() },
    app: { headerLabel: 'Заявка', filterComponent: LotInfoFilter, value: getLotInfoValue() },
    tagIds: { headerLabel: 'Теги', invisible: !tags.value.length, filterComponent: LotTagsFilter, value: getTagsValue(preFiltrationForm.value.tagIds, tags.value), valueAsHTML: true },
  }))

  const contractColumns = computed(() => ({
    costContract: { headerLabel: 'Цена', isSort: true, filterComponent: CostContractFilter, value: getContractCostValue(), placement: 'bottom-start' },
    costContractGuarantee: { headerLabel: 'Размер обеспечений', filterComponent: GuaranteeFilter, value: getContractGuaranteeValue(), placement: 'bottom-start' },
    dateContractSign: { headerLabel: 'Даты', isSort: true, filterComponent: DateContractFilter, value: getContractDatesValue(), placement: 'bottom-start' },
    status: { headerLabel: 'Статус контракта', isSort: true, filterComponent: ContractStatusFilter, value: getContractStatusValue() },
    contract: { headerLabel: 'Контракт', filterComponent: LotInfoFilter, value: getLotInfoValue() },
    tagIds: { headerLabel: 'Теги', invisible: !tags.value.length, filterComponent: LotTagsFilter, value: getTagsValue(preFiltrationForm.value.tagIds, tags.value), valueAsHTML: true },
  }))

  return {
    searchColumns,
    appsColumns,
    contractColumns,
    hasActiveFilters,
    onResetClick,
  }
}
